import * as React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Link, Text, VStack, Tag } from '@chakra-ui/react'
import { caseDescNotes } from './archive_case_desc.module.css'

const ArchiveCaseDesc = ({photos,keywords,takenAt,venueName,companyName,notesHtml}) => {
  return (
    <Box display={{ lg: 'flex' }}>
      <VStack pr={{ md: 4 }}>
        {photos.localFiles.map((f) => {
          return(
            <Box pb='3' maxW='800px'>
              <GatsbyImage image={getImage(f)} />
            </Box>
          )
        })}
      </VStack>
      <Box p={{ md: 4 }} w={{ lg: '400px' }}>
        <Text pt={{ base: 5, md: 0 }} pb={{ base: 5, md: 5 }}>
          {takenAt}・{venueName}（{companyName}）
        </Text>
        <Text>
          {keywords.map((k) => {
            let keyword = k.data.Name
            return (
              <Link
                _hover={{
                  textDecoration: 'none',
                }}
                href={`/archive/keyword/${keyword}`}>
                <Tag size="md" borderRadius="base" mb="2" mr="2">{keyword}</Tag>
              </Link>
            )
          })}
        </Text>
        <Box fontSize='lg' mt='10'>
          <Box
            className={caseDescNotes} 
            dangerouslySetInnerHTML={{
            __html: notesHtml
            }}>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ArchiveCaseDesc