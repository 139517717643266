import React from "react"
import { graphql } from "gatsby"
import { Box } from '@chakra-ui/react'
import Layout from '../../components/layout'
import ArchiveCaseDesc from '../../components/archive_case_desc'

const ArchiveCasePage = ({ data }) => {
  if (data.airtable) {
    const atd = data.airtable.data

    let notesHtml = ''
    if (atd.Notes) {
      notesHtml = atd.Notes.childMarkdownRemark.html
    }

    return(
      <Layout pageTitle={"事例 ⇢ " + atd.Name}>
        <ArchiveCaseDesc 
          photos={atd.Photos}
          keywords={atd.Keywords}
          takenAt={atd.Taken_at}
          venueName={atd.Venue[0].data.Name}
          companyName={atd.Company[0].data.Name}
          notesHtml={notesHtml}
        />
      </Layout>
    )
  } else {
    return(<Box></Box>)
  }
}

export const query = graphql`
  query($id: String) {
    airtable(table: {eq: "Cases"}, id: {eq: $id}) {
      id
      data {
        Name
        Id
        Keywords {
          data {
            Name
          }
        }
        Photos {
          localFiles {
            childImageSharp {
              gatsbyImageData(breakpoints: [800, 1600, 2400])
            }
          }
        }
        Company {
          data {
            Name
          }
        }
        Venue {
          data {
            Name
          }
        }
        Taken_at(formatString: "YYYY年M月")
        Notes {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default ArchiveCasePage